import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, Checkbox, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { getPickupListByID, generateNewTracking } from 'utils/apis/pickup-track';
import { readStringDate } from 'assets/function utils/functionUtils';
import { useSelector } from 'react-redux';
import { loggedInUserSelector } from 'store/slices/user';

const PickupListSelectorPopup = ({ courierPartnerList, setOpen }) => {
    const [slectedPickup, setSelectedPick] = React.useState({});
    const [selectedCourierPartner, setSelectedCourierPartner] = React.useState(null);
    const [pcikupList, setPickupList] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const loggedInUserData = useSelector(loggedInUserSelector);
    React.useEffect(() => {
        setPickupList((prev) =>
            prev.map((data) => ({
                ...data,
                checked: Boolean(slectedPickup[data.id])
            }))
        );
    }, [slectedPickup]);
    const onChangeSelectPickup = (event, id) => {
        console.log(slectedPickup, id);
        if (event.target.checked) {
            setSelectedPick((prev) => ({ ...prev, [id]: true }));
        } else {
            setSelectedPick((prev) => {
                delete prev[id];
                return { ...prev };
            });
        }
    };
    const handleChange = (event) => {
        setSelectedCourierPartner(event.target.value);
        setSelectedPick({});
    };
    const fetchPickupListById = async (data) => {
        setIsLoading(true);
        const list = await getPickupListByID(data);
        setPickupList(list.map((data) => ({ ...data, checked: false })));
        setIsLoading(false);
    };
    const generateTrackId = async () => {
        const pickupRequests = Object.keys(slectedPickup);
        setIsLoading(true);
        const data = {
            added_by: loggedInUserData?.userID,
            pickup_requests: pickupRequests,
            pickup_request_count: pickupRequests.length,
            delivery_partner: selectedCourierPartner
        };
        setOpen(false);
        try {
            const response = await generateNewTracking(data);
            alert(`New Track ID Generated : ${response}`);
            window.location.reload();
        } catch (err) {
            console.log(err);
        }

        setIsLoading(false);
    };

    React.useEffect(() => {
        const data = {
            status: 1,
            courierPartner: selectedCourierPartner
        };
        fetchPickupListById(data);
    }, [selectedCourierPartner]);
    return (
        <Box sx={{ textAlign: 'center' }}>
            <FormControl sx={{ m: 1, minWidth: 300 }}>
                <InputLabel id="demo-controlled-open-select-label">Select Delivery Partner</InputLabel>
                <Select labelId="label" id="select" value={selectedCourierPartner} label="Select Delivery Partner" onChange={handleChange}>
                    {courierPartnerList.map((data) => (
                        <MenuItem value={data.id}>{data.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <>
                {selectedCourierPartner > 0 ? (
                    <>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell> Select </TableCell>
                                        <TableCell> Pickup Id</TableCell>
                                        <TableCell align="right">Customer Name </TableCell>
                                        <TableCell align="right">Delivery Location </TableCell>
                                        <TableCell align="right">Requested Date</TableCell>
                                        <TableCell align="right">Requested Accepted By </TableCell>
                                        <TableCell align="right">Status </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {pcikupList.map((row) => (
                                        <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell align="right">
                                                <Checkbox
                                                    checked={row.checked}
                                                    size="small"
                                                    onChange={(event) => onChangeSelectPickup(event, row.id)}
                                                />
                                            </TableCell>
                                            <TableCell align="right">{row.pickup_id}</TableCell>
                                            <TableCell align="right">{row?.customerName}</TableCell>
                                            <TableCell align="right">Wheelock Scientific Services Ltd</TableCell>
                                            <TableCell align="right">{readStringDate(row.added_date)}</TableCell>
                                            <TableCell align="right">{readStringDate(row.pickup_date)} </TableCell>
                                            <TableCell align="right">Accepted</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Button
                            onClick={generateTrackId}
                            disabled={pcikupList.length === 0 || Object.keys(slectedPickup).length === 0}
                            variant="contained"
                            size="small"
                            sx={{ textAlign: 'center', margin: '0 auto' }}
                        >
                            {' '}
                            Generate Track-ID
                        </Button>
                    </>
                ) : (
                    <Typography color="error" sx={{ marginTop: '25px' }}>
                        {' '}
                        Please Select Delivery Partner !!
                    </Typography>
                )}
            </>
        </Box>
    );
};

export default PickupListSelectorPopup;

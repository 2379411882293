import React, { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Chip,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    Link,
    Button,
    TableRow,
    IconButton
} from '@mui/material';

// assets

import TestRequestMenu from 'ui-component/MuiReusableComponents/Menus/TestRequestMenu';
import {
    testRequestsSelector,
    pageSelector,
    rowsPerPageSelector,
    setRowsPerPage,
    setPage,
    startTestRequestData,
    startTestRequestDetailsByTestRequestId
} from 'store/slices/testRequest/testRequests';
import { useDispatch, useSelector } from 'react-redux';
import PopupDialogs from 'ui-component/MuiReusableComponents/MuiPopupDialog/new-popup';
import ConformationPopup from 'ui-component/MuiReusableComponents/DeletePopup/deletePopup';
import { startAddUpdateLabTest } from 'store/slices/lab/lab';
import { readStringDate, sendDateFormate } from 'assets/function utils/functionUtils';
import PickupListSelectorPopup from './pickuplistPopup';
import { getTrackingDetilsByTackingID, getTrackingLocationHistory, getTrackingList, updateTrackIdStatus } from 'utils/apis/pickup-track';
import TrackingDetailsPopup from './trackingDetails-popup';
import TrackLocationHistoryPopup from './trackingLocationHistory-popup';
import AnimatedLoading from 'ui-component/MuiReusableComponents/AnimationLoading/animationLoading';
import LocationOnIcon from '@mui/icons-material/LocationOn';
// ==============================|| Test Request In-transit||============================== //

const TrackingList = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const page = useSelector(pageSelector);
    const rowsPerPage = useSelector(rowsPerPageSelector);
    const testData = useSelector(testRequestsSelector);

    const [trackingList, setTrackingList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [trackData, setTrackData] = useState([]);
    const [trackLocationHistory, setTrackLocationHistory] = useState([]);
    const [open, setOpen] = useState(false);
    const [openLocation, setOpenLocation] = useState(false);

    const handleChangePage = (event, newPage) => {
        dispatch(setPage(newPage));
    };

    const handleChangeRowsPerPage = (event) => {
        dispatch(setRowsPerPage(event.target.value));
        dispatch(setPage(0));
    };

    const markAsRecieved = async (row) => {
        // set the selected row
        try {
            const res = await updateTrackIdStatus(row?.rowKey, 'COMPLETED');
            window.location.reload();
        } catch (err) {
            alert(err);
        }
    };
    const fecthTrackingList = async () => {
        setIsLoading(true);
        const response = await getTrackingList();
        setTrackingList(response?.results);
        setIsLoading(false);
    };
    useEffect(() => {
        fecthTrackingList();
    }, []);
    const fetchTrackingDetailsByTrackId = async (trackId) => {
        const response = await getTrackingDetilsByTackingID(trackId);
        setTrackData(response);
    };
    const fetchTrackingLocationHistory = async (trackId) => {
        const locationHistory = await getTrackingLocationHistory(trackId);
        const response = await getTrackingDetilsByTackingID(trackId);
        setTrackData(response);
        setTrackLocationHistory(locationHistory);
    };
    return (
        <>
            {isLoading && <AnimatedLoading />}
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ pl: 3 }}>#</TableCell>
                            <TableCell>Tracking Id</TableCell>
                            <TableCell>Generated By</TableCell>
                            <TableCell> Generated Date</TableCell>
                            <TableCell> Status</TableCell>
                            <TableCell align="center" sx={{ pr: 3 }}>
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {trackingList.map((row, index) => (
                            <TableRow sx={{ bgcolor: index % 2 === 0 ? theme?.palette?.grey[50] : '' }} key={index}>
                                <TableCell size="small" sx={{ pl: 3 }}>
                                    {index + 1}
                                </TableCell>
                                <TableCell size="small">
                                    <Link
                                        onClick={() => {
                                            setOpen(true);
                                            fetchTrackingDetailsByTrackId(row.rowKey);
                                        }}
                                        sx={{ cursor: 'pointer' }}
                                        title="sample details"
                                    >
                                        {row.rowKey}
                                    </Link>
                                </TableCell>
                                <TableCell size="small">{row.addedByName}</TableCell>
                                <TableCell size="small">{readStringDate(row.timestamp)}</TableCell>
                                <TableCell size="small">{row.status}</TableCell>

                                <TableCell size="small" align="center" sx={{ pr: 3 }}>
                                    <Button
                                        onClick={() => {
                                            console.log(row.status, ' row.status');
                                            markAsRecieved(row);
                                        }}
                                        variant="contained"
                                        size="small"
                                        sx={{ marginLeft: '10px' }}
                                        disabled={row.status !== 'DELIVERED'}
                                    >
                                        Mark as Received
                                    </Button>
                                    <IconButton>
                                        <LocationOnIcon
                                            onClick={() => {
                                                setOpenLocation(true);
                                                fetchTrackingLocationHistory(row.rowKey);
                                            }}
                                            sx={{ cursor: 'pointer' }}
                                            title="sample details"
                                        />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={testData[1] && testData[1][0].total_count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
            <PopupDialogs
                title={`Tracking ID ${trackData?.trackingDetail?.[0]?.rowKey}`}
                needTitleBar
                size="lg"
                open={open}
                setOpen={setOpen}
            >
                {trackData?.trackingDetail?.length > 0 && <TrackingDetailsPopup trackData={trackData} />}
            </PopupDialogs>

            <PopupDialogs title="Location History" needTitleBar size="lg" open={openLocation} setOpen={setOpenLocation}>
                {trackLocationHistory?.length > 0 && trackData?.trackingDetail?.length > 0 && (
                    <TrackLocationHistoryPopup trackLocationData={trackLocationHistory} trackData={trackData} />
                )}
            </PopupDialogs>
        </>
    );
};

export default TrackingList;

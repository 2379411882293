import { securedAxiosAPI } from 'utils/axios';

const securedRoute = {
    pickups: 'pickupRequest/pickup-list-by-status/##STATUS##/##COURIERID##',
    generateNewTracking: 'pickupRequest/create-tracking-request',
    trackingList: 'pickupRequest/trackings',
    getTrackDetailsByTackId: 'pickupRequest/tracking?trackingId=##TRACKID##',
    getTrackLocationHistory: 'pickupRequest/tracking/location-history/##TRACKID##',
    updateTrackIdStatus: 'pickupRequest/tracking/##TRACKID##/status'
};
// Pcikup List by Courier User ID
export const getPickupListByID = async (data) => {
    const { status, courierPartner } = data;
    const res = await securedAxiosAPI.get(
        securedRoute?.pickups.replaceAll('##STATUS##', status).replaceAll('##COURIERID##', courierPartner)
    );
    return res.data;
};
// Generate new Track Id
export const generateNewTracking = async (data) => {
    const res = await securedAxiosAPI.post(securedRoute?.generateNewTracking, data);
    return res.data;
};
// Tracking List
export const getTrackingList = async () => {
    const res = await securedAxiosAPI.get(`${securedRoute?.trackingList}?page=1&limit=1000`);
    return res.data;
};
/// Tracking Info
export const getTrackingDetilsByTackingID = async (trackId) => {
    const res = await securedAxiosAPI.get(securedRoute?.getTrackDetailsByTackId.replaceAll('##TRACKID', trackId));
    return res.data;
};
// Update
export const updateTrackIdStatus = async (trackID, newStatus) => {
    //  alert(securedRoute?.updateTrackIdStatus.replaceAll('##TRACKID##', trackID));
    const res = await securedAxiosAPI.patch(securedRoute?.updateTrackIdStatus.replaceAll('##TRACKID##', trackID), { status: newStatus });
    return res.data;
};

/// Tracking Info
export const getTrackingLocationHistory = async (trackId) => {
    const res = await securedAxiosAPI.get(securedRoute?.getTrackLocationHistory.replaceAll('##TRACKID', trackId));
    return res.data?.results;
};

import * as React from 'react';
// material-ui
import { Button, Grid, Typography, Badge, Box, TextField, Paper } from '@mui/material';
import { Link } from 'react-router-dom';
// project imports
import InTrackingList from './tracking-list';
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';
import PopupDialogs from 'ui-component/MuiReusableComponents/MuiPopupDialog/new-popup';

// assets

import FilterListIcon from '@mui/icons-material/FilterListTwoTone';
import { useDispatch, useSelector } from 'react-redux';

import CloseIcon from '@mui/icons-material/Close';
import {
    startAllTestRequest,
    testRequestsSelector,
    isLoadingSelector,
    isLoading2Selector,
    rowsPerPageSelector,
    startTestRequestDataByFilter,
    setRowsPerPage,
    setPage,
    pageSelector,
    testRequestDataSelector,
    testRequestDetailssSelector
} from 'store/slices/testRequest/testRequests';
import AnimatedLoading from 'ui-component/MuiReusableComponents/AnimationLoading/animationLoading';
import { usersListByRoleId } from 'utils/apis/user';
import TestDetailsComp from 'views/common-component/test-req-details';
import PickupListSelectorPopup from './pickuplistPopup';
import { Add } from '@mui/icons-material';

// ==============================|| USER LIST STYLE 1 ||============================== //

const TrackingList = () => {
    const [filterStatus, setFilterStatus] = React.useState(false);
    const [filterData, setFilterData] = React.useState({});

    const [open, setOpen] = React.useState(false);
    const [courierPartnerList, setCourierPartnerList] = React.useState([]);

    const testReqData = useSelector(testRequestDataSelector);
    const testRequestDetail = useSelector(testRequestDetailssSelector);

    const dispatch = useDispatch();
    const testData = useSelector(testRequestsSelector);
    const isLoading = useSelector(isLoadingSelector);
    const isLoading2 = useSelector(isLoading2Selector);
    const page = useSelector(pageSelector);
    const rowsPerPage = useSelector(rowsPerPageSelector);
    const fetchCourierUserByRoleId = async () => {
        const list = await usersListByRoleId(4);
        setCourierPartnerList(list);
    };
    React.useEffect(() => {
        dispatch(startAllTestRequest({ status: 0 }));
    }, [page, rowsPerPage]);

    React.useEffect(() => {
        fetchCourierUserByRoleId();
    }, []);
    const onclickFilter = () => setOpen(!open);
    return (
        <>
            <MainCard
                title={
                    <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                        <Grid item>
                            <Typography variant="h3">Gaiapod Tracking List</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
                            <Button
                                variant="contained"
                                onClick={onclickFilter}
                                sx={{ marginRight: '15px' }}
                                startIcon={
                                    filterStatus ? (
                                        <Badge size="small" badgeContent={1} color="error" title="filter active">
                                            <FilterListIcon size="small" />
                                        </Badge>
                                    ) : (
                                        <Add size="small" />
                                    )
                                }
                            >
                                Generate Tracking-ID
                            </Button>
                        </Grid>
                    </Grid>
                }
                content={false}
            >
                {isLoading2 ? <AnimatedLoading /> : <InTrackingList />}
            </MainCard>
            <PopupDialogs title="Generate New Tracking ID" needTitleBar size="lg" open={open} setOpen={setOpen}>
                <PickupListSelectorPopup courierPartnerList={courierPartnerList} setOpen={setOpen} />
            </PopupDialogs>
        </>
    );
};
export default TrackingList;

import { readStringDate } from 'assets/function utils/functionUtils';
import { useState } from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    MultiListTableComp
} from 'views/pages/manage-test-requests/new-test-sample/previewForm';

const { Grid, CardHeader, Typography, TableHead, TableRow, TableCell, Table, TableBody, useTheme } = require('@mui/material');
const { gridSpacing } = require('store/constant');
const { default: DTable } = require('ui-component/Table/detailsTable');

const tdStyle = { padding: '5px' };

const TrackingDetailsPopup = ({ trackData }) => {
    const theme = useTheme();
    const [expanded, setExpanded] = useState('none');
    const handleChangeAccordian = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    return (
        <>
            <Grid container columnSpacing={gridSpacing}>
                <Grid item xs={12}>
                    <CardHeader subheader={<Typography variant="subtitle1"> Tracking Details </Typography>} sx={{ paddingBottom: 0 }} />
                </Grid>
                <Grid item xs={12}>
                    <Grid container sx={{ paddingLeft: '1rem' }}>
                        <Grid item xs={6}>
                            <DTable>
                                <tbody>
                                    <tr style={{ padding: '10px' }}>
                                        <td style={tdStyle}> Tracking Id : </td>
                                        <td style={tdStyle}>{trackData?.trackingDetail[0].rowKey} </td>
                                    </tr>
                                    <tr>
                                        <td style={tdStyle}>Generated By : </td>
                                        <td style={tdStyle}>{trackData?.trackingDetail[0].addedByName}</td>
                                    </tr>

                                    <tr>
                                        <td style={tdStyle}>Generated Date : </td>
                                        <td style={tdStyle}>{readStringDate(trackData?.trackingDetail[0].timestamp)}</td>
                                    </tr>
                                </tbody>
                            </DTable>
                        </Grid>
                        <Grid item xs={6}>
                            <DTable>
                                <tbody>
                                    <tr>
                                        <td style={tdStyle}>Courier Partner : </td>
                                        <td style={tdStyle}>{trackData?.trackingDetail[0].deliveryPartnerName}</td>
                                    </tr>

                                    <tr>
                                        <td style={tdStyle}>Current Status : </td>
                                        <td style={tdStyle}>{trackData?.trackingDetail[0].status}</td>
                                    </tr>
                                </tbody>
                            </DTable>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: '1rem' }}>
                    {trackData?.pickupListDetails.map((data, index) => (
                        <Accordion expanded={expanded === `panel${index}`} onChange={handleChangeAccordian(`panel${index}`)}>
                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                <Typography> {data.pickup_id}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <DTable>
                                    <tbody>
                                        <tr>
                                            <td style={tdStyle}>Customer Name : </td>
                                            <td style={tdStyle}>{data?.customer_name}</td>
                                        </tr>

                                        <tr>
                                            <td style={tdStyle}>Current Status : </td>
                                            <td style={tdStyle}>{trackData?.Status}</td>
                                        </tr>
                                    </tbody>
                                </DTable>
                                {data?.pickupDetails.map((samples) => (
                                    <Table>
                                        <TableHead bgcolor={theme.palette.warning.light}>
                                            <TableRow size="small">
                                                <TableCell size="small">Sample Id :</TableCell>
                                                <TableCell align="right" size="small">
                                                    Created Date :
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableCell size="small">{samples?.sample_id} </TableCell>
                                            <TableCell align="right" size="small">
                                                {readStringDate(samples?.added_date)}
                                            </TableCell>
                                        </TableBody>
                                    </Table>
                                ))}
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Grid>
            </Grid>
        </>
    );
};

export default TrackingDetailsPopup;

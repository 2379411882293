import { readStringDate, readStringDateTime } from 'assets/function utils/functionUtils';
import { useState } from 'react';

const {
    Grid,
    CardHeader,
    Typography,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    useTheme,
    Table,
    TableContainer
} = require('@mui/material');
const { gridSpacing } = require('store/constant');
const { default: DTable } = require('ui-component/Table/detailsTable');

const tdStyle = { padding: '5px' };

const TrackingDetailsPopup = ({ trackData, trackLocationData }) => {
    const theme = useTheme();
    const [expanded, setExpanded] = useState('none');
    const handleChangeAccordian = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    return (
        <Grid container columnSpacing={gridSpacing}>
            <Grid item xs={12}>
                <CardHeader subheader={<Typography variant="subtitle1"> Tracking Details </Typography>} sx={{ paddingBottom: 0 }} />
            </Grid>
            <Grid item xs={12}>
                <Grid container sx={{ paddingLeft: '1rem' }}>
                    <Grid item xs={6}>
                        <DTable>
                            <tbody>
                                <tr style={{ padding: '10px' }}>
                                    <td style={tdStyle}> Tracking Id : </td>
                                    <td style={tdStyle}>{trackData?.trackingDetail[0].rowKey} </td>
                                </tr>
                                <tr>
                                    <td style={tdStyle}>Generated By : </td>
                                    <td style={tdStyle}>{trackData?.trackingDetail[0].addedByName}</td>
                                </tr>

                                <tr>
                                    <td style={tdStyle}>Generated Date : </td>
                                    <td style={tdStyle}>{readStringDate(trackData?.trackingDetail[0].timestamp)}</td>
                                </tr>
                            </tbody>
                        </DTable>
                    </Grid>
                    <Grid item xs={6}>
                        <DTable>
                            <tbody>
                                <tr>
                                    <td style={tdStyle}>Courier Partner : </td>
                                    <td style={tdStyle}>{trackData?.trackingDetail[0].deliveryPartnerName}</td>
                                </tr>

                                <tr>
                                    <td style={tdStyle}>Current Status : </td>
                                    <td style={tdStyle}>{trackData?.trackingDetail[0].status}</td>
                                </tr>
                            </tbody>
                        </DTable>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{ marginTop: '1rem' }}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ pl: 3 }}>#</TableCell>
                                <TableCell>Date Time</TableCell>
                                <TableCell>Location</TableCell>
                                <TableCell>Description</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {trackLocationData?.map((data, index) => (
                                <TableRow sx={{ bgcolor: index % 2 === 0 ? theme?.palette?.grey[50] : '' }} key={index}>
                                    <TableCell size="small" sx={{ pl: 3 }}>
                                        {index + 1}
                                    </TableCell>
                                    <TableCell size="small">{readStringDateTime(data.timestamp)}</TableCell>
                                    <TableCell size="small">{data?.locationName}</TableCell>
                                    <TableCell size="small">{data?.description}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
};

export default TrackingDetailsPopup;
